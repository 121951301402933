const palette = {
  background: {
    default: '#c3b091',
    image: 'images/selfie-mad-face.png',
  },
  text: {
    primary: '#17252A',
    secondary: '#17252A',
    weight: '400',
  },
  primary: {
    light: '#17252A',
    main: '#17252A',
    dark: '#17252A',
    contrastText: '#fff',
  },
  secondary: {
    light: '#6affff',
    main: '#3AAFA9',
    dark: '#00a7bc',
    contrastText: '#fff',
  },
};

export default palette;
